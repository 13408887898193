<template>
  <div class="movie-app__container movie-app__pt-4 movie-app__pb-4">
    <div class="movie-app__row">
      <div class="movie-app__col-xs-12">
        <router-link :to="{ name: 'home'}">
          <div class="movie-app__link">
            <span class="movie-app__svg_back">
              <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 7.28951L1.19884 3.99941L4 0.710494L3.39203 0L0 4.00059L3.39203 8L4 7.28951Z" fill="#29293A"/>
              </svg>
              Zurück
            </span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <div v-if="movie.movie_image" class="movie-app__header">
    <img :src="movie.movie_image" class="movie-app__header-img"/>
  </div>
  <div class="movie-app__container movie-app__header-container">
    <div class="movie-app__row">
      <div class="movie-app__col-xs-12 movie-app__text-center">
        <h1 class="movie-app__page-header-article">                 
          <span class="movie-app__page-header-article__title">{{ movie.title }}</span>
        </h1>
        <p class="movie-app__page-header-article__overline">
          <span v-if="movie.genre">{{ movie.genre }} </span>
          <span v-if="movie.country"> | {{ movie.country }} ({{ movie.year }}) </span>
          <span v-if="movie.fsk"> | ab {{ movie.fsk }} </span>
          <span v-if="movie.length"> | Laufzeit: {{ movie.length }} </span>
          <span v-if="movie.start"> | Neustart: {{ movie.start }} </span>
        </p>
      </div>
    </div>
  </div>
  <div class="movie-app__movie-container">
      <div v-if="movie.teaser" class="movie-app__container">
        <div class="movie-app__row">
          <div class="movie-app__col-xs-12">
            <p class="movie-app__font_3">{{ movie.teaser }}</p>
          </div>
        </div>
      </div>
      <div class="movie-app__container movie-app__movie-data">
        <div v-if="movie.director" class="movie-app__row">
          <div class="movie-app__col-xs-4">
            <p><b>Regie</b></p>
          </div>
          <div class="movie-app__col-xs-8">
            <p>{{ movie.director }}</p>
          </div>
        </div>
        <div v-if="movie.actor" class="movie-app__row">
          <div class="movie-app__col-xs-4">
            <p class="movie-app__mt-2"><b>Darsteller</b></p>
          </div>
          <div class="movie-app__col-xs-8">
            <p class="movie-app__mt-2">{{ movie.actor }}</p>
          </div>
        </div>
      </div>
      <div v-if="movie.trailer" class="movie-app__container movie-app__pt-4 movie-app__pb-4">
        <div class="movie-app__row">
          <div class="movie-app__col-xs-12">
            <div class="responsive-video">
              <iframe :src="movie.trailer"></iframe>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Tab from '../components/Tab.vue'
import TabsWrapper from '../components/TabsWrapper.vue'
import Playtimes from '../components/Playtimes.vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'
export default {
  name: 'WrapAround',
  components: { Tab, TabsWrapper, Playtimes, Carousel, Slide, Navigation},
  props: ['id'],
  data() {
    return {
      componentKey: 0,
      search: '',
      selectedDay: new Date().getDay(),
      aktDayButton: new Date().getDay(),
      aktDay: new Date().getDay(),
      wrapperDynamicKey: 0,
      countMovies: 0,
      movie: [],
      cinemaList: [],
      dayName: '',
      videoPoster: '',
      movieFound: true,
      products: [
        {id: '4', name: 'Do. 18.01.', date: '18.01.'},
        {id: '5', name: 'Fr. 19.01.', date: '19.01.'},
        {id: '6', name: 'Sa. 20.01.', date: '20.01.'},
        {id: '0', name: 'So. 21.01.', date: '21.01.'},
        {id: '1', name: 'Mo. 22.01.', date: '22.01.'},
        {id: '2', name: 'Di. 23.01.', date: '23.01.'},
        {id: '3', name: 'Mi. 24.01.', date: '24.01.'},
      ],
      settings: {
          itemsToShow: 1.5,
          snapAlign: 'center',
      }
    }
  },
  methods: {
    async getData() {
      await fetch("https://interface.datenlieferung-streamingdienste.de/api/entry/streamingById/" + this.id)
        .then(res => res.json())
        .then(data => this.movie = data)
        .catch(err => console.log(err.message));      
    }
  },
  mounted() {
      this.getData()
  },
}
</script>