<template>
  <div class="movie-app__container movie-app__pt-4 movie-app__pb-4">
    <div class="movie-app__row">
      <div class="movie-app__col-xs-12">
        <h1 class="movie-app__text-center movie-app__font_2">LuS Kino</h1>
        <p class="movie-app__text-center movie-app__font_sub_headline">am 6.2.2024 ab 17 Uhr</p>
      </div>
    </div>
  </div>
  <TabsWrapper>
    <Tab title="Filme Vorschläge"><MovieList/></Tab>
    <Tab title="Voting"><CinemaList/></Tab>
  </TabsWrapper> 
</template>

<script>
import PosterSlider from '../components/PosterSlider.vue'
import CinemaList from '../components/CinemaList.vue'
import MovieList from '../components/MovieList.vue'
import Tab from '../components/Tab.vue'
import TabsWrapper from '../components/TabsWrapper.vue' 

export default {
  components: { PosterSlider, CinemaList, MovieList, Tab, TabsWrapper}
}

</script>
