<template>
<div class="movie-app__container">
  <Carousel v-bind="settings" :breakpoints="breakpoints" :wrap-around="true">
    <Slide v-for="slide in listItems" :key="slide">
        <div class="movie-app__carousel__item">
            <router-link :to="{ name: 'movie', params: { id: slide.movieId, pageTitle: slide.title }}">
                <img :src="slide.moviePoster" />
            </router-link>
        </div>
    </Slide>
    <template #addons>
        <navigation>
            <template #next>
                    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="arrows">
                        <path id="arrow small" d="M1.24227e-07 14.579L5.60233 7.99883L1.27454e-06 1.42099L1.21595 -5.93081e-07L7.99801 7.99883L8 7.99883L7.99901 8L8 8.00117L7.99801 8.00117L1.21595 16L1.24227e-07 14.579Z" fill="#29293A"/>
                        </g>
                    </svg>
            </template>
            <template #prev>
                    <svg class="movie-app__rotate" width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="arrows">
                        <path id="arrow small" d="M1.24227e-07 14.579L5.60233 7.99883L1.27454e-06 1.42099L1.21595 -5.93081e-07L7.99801 7.99883L8 7.99883L7.99901 8L8 8.00117L7.99801 8.00117L1.21595 16L1.24227e-07 14.579Z" fill="#29293A"/>
                        </g>
                    </svg>
            </template>
        </navigation>

    </template>

  </Carousel>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default ({
    name: 'WrapAround',
    data: () => ({
        settings: {
            itemsToShow: 2.5,
            itemsToScroll: 2,
            snapAlign: 'start',
            mouseDrag: false
        },
        breakpoints: {
            600: {
                itemsToShow: 3.5,
                itemsToScroll: 3,
                snapAlign: 'start',
            },
            800: {
                itemsToShow: 4.5,
                itemsToScroll: 4,
                snapAlign: 'start',
            },
            1024: {
                itemsToShow: 4.5,
                itemsToScroll: 4,
                snapAlign: 'start',
            },
            1524: {
                itemsToShow: 4.5,
                itemsToScroll: 4,
                snapAlign: 'start',
            },
        },
        listItems: []
    }),
    methods: {
        async getData() {
            await fetch("https://interface.datenlieferung-streamingdienste.de/api/entry/poster")
                .then(res => res.json())
                .then(data => this.listItems = data)
                .catch(err => console.log(err.message));
        }
    },
    mounted() {
        this.getData()
    },
    components: {
        Carousel,
        Slide,
        Navigation,
    },
})
</script>

<style>
.carousel__item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__item img {
    max-height: 250px;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  background-color: #fff;
  border-radius: 50%;
}
</style>