<template>

        <div class="movie-app__container movie-app__pt-4">
            <div class="movie-app__row"  v-if="!movieFound">
                <div class="movie-app__col-xs-12 movie-app__pt-5">
                    <h2>Bitte schreibt mir per Teams oder per Mail, 2 Filme außer eurem eignen, welche Ihr gerne schauen wollt.</h2>
                </div>
            </div>
        </div>

</template>

<script>
export default ({
    data() {
        return {
            search: '',
            searchDeleted: '',
            listItems: [],
            cinemaFound: true,
        }
    },
    methods: {
        async getData() {
            await fetch("https://interface.datenlieferung-streamingdienste.de/api/entry/cinemaList")
                .then(res => res.json())
                .then(data => this.listItems = data)
                .catch(err => console.log(err.message));
        },
        say(message) {
            alert(message)
        }
    },
    mounted() {
        this.getData()
    },
    computed: {
        filteredList() {
            const result = this.listItems.filter(post => {
                return post.name.toLowerCase().includes(this.search.toLowerCase()) || post.address.toLowerCase().includes(this.search.toLowerCase()) || post.place.toLowerCase().includes(this.search.toLowerCase())
            })
            if (result.length) {
                this.cinemaFound = true
            } else {
                this.cinemaFound = false
            }
            return result
        }
    }
})
</script>