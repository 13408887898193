<template>
    <div class="movie-app__container movie-app__pt-4">
        <div class="movie-app__row movie-app__row-flex">
            <div v-for="item in listItems" :key="item.movie_id" class="movie-app__col-xs-6 movie-app__col-sm-4 movie-app__col-md-3 movie-app__pb-2">
                <router-link :to="{ name: 'movie', params: { id: item.movie_id, pageTitle: item.title }}" class="movie-app__link-movie-list">
                    <div class="movie-app__col-xs-12 movie-app__p-0">
                        <div class="movie-app__list-movie-image" :style="{ backgroundImage: 'url(' + item.moviePoster + ')' }"
></div>
                    </div>
                    <div class="movie-app__col-xs-12 movie-app__p-sm-0">
                        <h4 class="movie-app__list-movie-name">{{ item.title }} {{ item.ov }}</h4>
                        <p class="movie-app__list-movie-data">
                            {{ item.genre }}  | ab {{ item.fsk }}<br>
                            Jahr: {{ item.year }}
                        </p>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
    
</template>

<script>
export default ({
    data() {
        return {
            search: '',
            selectedDay: new Date().getDay(),
            aktDayButton: new Date().getDay(),
            aktDay: new Date().getDay(),
            listItems: [],
            dayName: '',
            movieFound: true,
            products: [
        {id: '4', name: 'Do. 18.01.', date: '18.01.'},
        {id: '5', name: 'Fr. 19.01.', date: '19.01.'},
        {id: '6', name: 'Sa. 20.01.', date: '20.01.'},
        {id: '0', name: 'So. 21.01.', date: '21.01.'},
        {id: '1', name: 'Mo. 22.01.', date: '22.01.'},
        {id: '2', name: 'Di. 23.01.', date: '23.01.'},
        {id: '3', name: 'Mi. 24.01.', date: '24.01.'},
            ],
        }
    },
    methods: {
        async getData() {
            await fetch("https://interface.datenlieferung-streamingdienste.de/api/entry/movieListStream")
                .then(res => res.json())
                .then(data => this.listItems = data)
                .catch(err => console.log(err.message));
            console.log(this.listItems)
        },
    },
    mounted() {
        this.getData()        
    },
})
</script>

<style>

</style>