<template>
    <div class="movie-app__container">
        <div class="movie-app__row">
                <Carousel v-if="tabTitles" v-bind="settings" :breakpoints="breakpoints">
                    <Slide v-for="tab in tabTitles" :key="tab.title">
                        <div
                        :key="tab.title"
                        class="movie-app__tabs__header-day carousel__item"
                        :class=" { movie_app__selected: tab.title == selectedTitle }"
                        @click="selectedTitle = tab.title">
                            <p class="movie-app__tabs__header-text movie-app__tabs__header-text-day">
                                {{ tab.title }} {{ tab.date }}
                            </p>
                        </div>
                    </Slide>

                    <template #addons>
                        <navigation>
                            <template #next>
                                    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="arrows">
                                        <path id="arrow small" d="M1.24227e-07 14.579L5.60233 7.99883L1.27454e-06 1.42099L1.21595 -5.93081e-07L7.99801 7.99883L8 7.99883L7.99901 8L8 8.00117L7.99801 8.00117L1.21595 16L1.24227e-07 14.579Z" fill="#29293A"/>
                                        </g>
                                    </svg>
                            </template>
                            <template #prev>
                                    <svg class="movie-app__rotate" width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="arrows">
                                        <path id="arrow small" d="M1.24227e-07 14.579L5.60233 7.99883L1.27454e-06 1.42099L1.21595 -5.93081e-07L7.99801 7.99883L8 7.99883L7.99901 8L8 8.00117L7.99801 8.00117L1.21595 16L1.24227e-07 14.579Z" fill="#29293A"/>
                                        </g>
                                    </svg>
                            </template>
                        </navigation>

                    </template>
                </Carousel>
        </div>
    </div>
    <slot />
</template>

<script>
import { ref, provide } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default {
    name: 'Basic',
    components: { Carousel, Slide, Navigation},
    data() {
        return {
            settings: {
                itemsToShow: 2,
                itemsToScroll: 2,
                snapAlign: 'start'
            },
            breakpoints: {
                600: {
                    itemsToShow: 3,
                    itemsToScroll: 3,
                    snapAlign: 'start'
                },
                800: {
                    itemsToShow: 4,
                    itemsToScroll: 4,
                    snapAlign: 'start'
                }
            },
        }
    },
    setup (props, { slots }) {
        const tabTitles = ref(slots.default()[0].children.map((tab) => tab.props))
        const selectedTitle = ref(tabTitles.value[0].title)

        provide("selectedTitle", selectedTitle)
        return {
            selectedTitle,
            tabTitles,
        }
    }
}
</script>

<style>
.carousel__item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  background-color: #fff;
  border-radius: 50%;
}
</style>